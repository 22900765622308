<template>
  <span @click="onToggle" :class="['toggle position-relative', isOn ? activeClass : inactiveClass]" :style="style">
    <span :class="[
      isOn ? 'toggle-on' : 'toggle-off',
      'toggle-circle position-absolute',
      circleClass,
    ]" :style="circleStyle + 'background-color:' + circleColor"></span>
  </span>
</template>

<script>
export default {
  emits: ["update:modelValue", "toggle"],
  props: {
    default: {
      type: Boolean,
    },
    activeColor: {
      type: String,
      default: "#30d158",
    },
    inactiveColor: {
      type: String,
      default: "#e5e5ea",
    },
    activeClass: {
      type: String,
      default: "",
    },
    inactiveClass: {
      type: String,
      default: "",
    },
    circleColor: {
      type: String,
      default: "#f2f2f7",
    },
    circleClass: {
      type: String,
      default: "",
    },
    circleStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOn: this.default,
    };
  },
  methods: {
    onToggle() {
      this.isOn = !this.isOn;
      this.$emit("update:modelValue", this.isOn);
      this.$emit("toggle", this.isOn);
    },
  },
  computed: {
    style: function () {
      const style = ["background-color", "border-color"]
        .map(
          (p) => `${p}: ${this.isOn ? this.activeColor : this.inactiveColor};`
        )
        .join("");
      return style;
    },
  },
};
</script>

<style scoped>
.toggle {
  box-sizing: content-box;
  height: 15px;
  width: 30px;
  border: 2px solid;
  border-radius: 9px;
  display: inline-block;
  transition: 200ms;
  cursor: pointer;
}

.toggle-circle {
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: 200ms;
}

.toggle-on {
  transform: translateX(100%);
}

.toggle-off {
  transform: translateX(0);
}
</style>
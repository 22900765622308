<template>
  <div v-ripple class="card card-hover rounded-lg text-center border-0 p-ripple"
    :style="'background-color:' + adFormat.backgroundColor">
    <div class="card-body rounded">
      <span class="ad-format-logo" :style="`background-image: url(${adFormat.iconUrl});`">
      </span>
    </div>

    <!-- <div class="ad-format-settings w-50 m-auto">
      <router-link
        :to="{
          name: 'Ad Format Settings',
          params: {
            adFormatName: lowerCase(adFormat.name),
            adFormat: adFormat,
          },
        }"
      >
        <i class="fas fa-cog" :style="`color: #fff`"></i>
      </router-link>
    </div> -->
    <div class="card-footer p-2 border-0">
      <h5 class="user-select-none my-auto text-white font-weight-medium">
        {{ adFormat.name }} <i class="fas fa-ad"></i>
      </h5>
    </div>
  </div>
</template>

<script>
import { lowerCase } from "lodash-es";
import Ripple from "primevue/ripple";

export default {
  directives: {
    ripple: Ripple,
  },
  props: {
    adFormat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    lowerCase,
  },
};
</script>

<style scoped>
.ad-format-logo {
  height: 2rem;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.card-footer {
  backdrop-filter: blur(20px);
  /* background-color: rgba(0, 0, 0, .8); */
  --glass-lightness: 0%;
  background: hsl(0 0% var(--glass-lightness) / 50%);
  backdrop-filter: blur(40px);
}
</style>
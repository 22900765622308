import { onMounted, toRaw } from "vue";

export function useDraft<T>(data: T, restoreFn: (d: T) => void) {
    function saveDraft(id: string) {
        if (id !== localStorage.getItem('draftId')) {
            if (undefined !== toRaw(data)) {
                localStorage.setItem('draftId', id);
                localStorage.setItem('draft', JSON.stringify(toRaw(data)));
            }
        }
    }
    
    function resetDraft() {
        localStorage.removeItem('draftId');
        localStorage.removeItem('draft');
    }

    onMounted(() => {        
        if ('true' !== localStorage.getItem('draftFeatureFlag')) {   
            resetDraft();
            return;
        }
      
        const draftId = crypto.randomUUID();
      
        window.addEventListener('visibilitychange', () => {
            if ('hidden' === document.visibilityState) {
                saveDraft(draftId);
            }
        });
      
        window.addEventListener('pagehide', () => {
            saveDraft(draftId);
        });
      
        const draft = localStorage.getItem('draft');
        if (null !== draft) {
            restoreFn(JSON.parse(draft));
        }
    });

    return { resetDraft };
}
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import AuthPage from '@/views/AuthPage'
import AuthCheck from '@/components/AuthCheck'
import AuthenticatedLayout from "@/views/AuthenticatedLayout"
import AdFormats from '@/views/AdFormats'
import AdFormatSettings from '@/views/AdFormatSettings'
import CampaignsList from '@/views/CampaignsList'
import CampaignResults from '@/views/CampaignResults'
import CampaignEdit from '@/views/CampaignEdit'
import CampaignCreation from '@/views/CampaignCreation'
import TargetGroups from '@/views/TargetGroupsPage'
import AdminUsersPage from '@/views/AdminUsers'
import NotFoundPage from '@/views/NotFound'
import TheSettings from '@/views/TheSettings'

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage
  },
  {
    path: '/auth/oauth',
    name: 'OAuth',
    component: AuthCheck
  },
  {
    path: '/',
    redirect: '/app'
  },
  {
    path: '/app',
    component: AuthenticatedLayout,
    children: [
      {
        path: '',
        redirect: '/app/ad-formats'
      },
      {
        path: 'ad-formats',
        name: 'Ad Formats',
        component: AdFormats,
        meta: { isNavLink: true, icon: "grid" }
      },
      {
        path: 'ad-formats/:adFormatName/new',
        name: 'New campaign',
        component: CampaignCreation,
        props: true
      },
      {
        path: 'ad-formats/:adFormatName/settings',
        name: 'Ad Format Settings',
        component: AdFormatSettings,
        props: true
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: CampaignsList,
        meta: { isNavLink: true, icon: "book" }
      },
      {
        path: 'campaigns/:id/edit',
        name: 'Campaign edit',
        component: CampaignEdit,
        props: true
      },
      {
        path: 'targets',
        name: 'Target Groups',
        component: TargetGroups,
        meta: { isNavLink: true, icon: "tag" }
      },
      {
        path: 'users',
        name: 'Users',
        component: AdminUsersPage,
        meta: { isNavLink: true, icon: "users" }
      },
      {
        path: 'campaigns/:adFormat/:id/results',
        name: 'Campaign results',
        component: CampaignResults,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: TheSettings,
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: NotFoundPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.user.isAuthenticated;
  switch (true) {
    case (to.path !== '/auth' && !isAuthenticated):
      next('/auth');
      break;
    case (to.path === '/auth' && isAuthenticated):
      next(from.path);
      break;
    default:
      next();
  }
})


export default router

<template>
  <Textarea
    v-bind="{ value: modelValue, autoResize }"
    class="form-control"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import Textarea from "primevue/textarea";
export default {
  emits: ["update:modelValue"],
  components: {
    Textarea,
  },
  props: {
    ...Textarea.props,
  },
};
</script>
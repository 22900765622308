<template>
    <section>
        <h3 class="mb-4">Experimental features</h3>
        <ul>
            <li class="d-flex items-center">
                <span class="feature-label">Draft</span>
                <InputSwitch 
                    v-model="isDraftEnabled" 
                    @change="onChange"
                />
            </li>
        </ul>
    </section>
</template>

<script setup>
import { ref } from 'vue';
import InputSwitch from 'primevue/inputswitch';

let isDraftEnabled = ref('true' === localStorage.getItem('draftFeatureFlag'));

const onChange = () => {
    localStorage.setItem('draftFeatureFlag', isDraftEnabled.value);
}
</script>

<style scoped>
.feature-label {
    margin: auto 12px auto 0;
}
</style>
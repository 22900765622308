import axios from "@/api";
import router from "@/router"

const state = () => ({
    isAuthenticated: localStorage.getItem("user") && true,
    user: { ...JSON.parse(localStorage.getItem("user")) }
})

// getters
const getters = {
    getUser(state) {
        return state;
    }
}

// actions
const actions = {
    authenticate({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            axios
                .post("/login/check", credentials)
                .then((res) => {
                    commit("setUser", res.data.user);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },

    logout({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/logout")
                .then((res) => {
                    commit("clearUserData");
                    router.push("/auth");
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        localStorage.setItem("user", JSON.stringify(user));
        state.user = user;
        state.isAuthenticated = true;
    },

    clearUserData(state) {
        localStorage.removeItem("user");
        state.isAuthenticated = false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}